import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Ausstellungen from './Routes/Ausstellungen';
import Biografie from './Routes/Biografie';
import Galerie from './Routes/Galerie';
import Impressum from './Routes/Impressum';
import Links from './Routes/Links';
import Literatur from './Routes/Literatur';

function App() {
  return (
    <div className="App">
      <Header />
      <div className='single-column'>
        <Routes>
          <Route path='/' element={<Navigate to='/galerie'/>}></Route>
          <Route path='/biografie' element={<Biografie />}></Route>
          <Route path='/galerie/*' element={<Galerie />}></Route>
          <Route path='/literatur' element={<Literatur/>}></Route>
          <Route path='/links' element={<Links />}></Route>
          <Route path='/ausstellungen' element={<Ausstellungen />}></Route>
          <Route path='/impressum' element={<Impressum />}></Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
