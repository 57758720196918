import React from "react";

type Props = {
}

const Biografie: React.FC<Props> = () => {

    return(
        <div className="content text-wrapper">
            <h1>Biografie Wolfgang Henning</h1>
            <table className="biografie">
                <tbody>
                    <tr><td>1946 am 15.Oktober</td><td>in Leutkirch im Allgäu geboren <br /> Aufgewachsen als Sohn des Malers Erwin Henning, der Mutter Irma Henning, geborene Hurt und der vier Jahre älteren Schwester Ursula. Im Elternhaus geprägt durch berufsspezifische Schwierigkeiten eines freischaffenden Malers der Nachkriegszeit in der Provinz.</td></tr>
                    <tr><td>1965–69</td><td>nach Abbruch einer kaufmännischen Lehre wegen Krankheit, handwerkliche Ausbildung im Atelie des Vaters. Einflüsse auf die eigene künstlerische Entwicklung durch die entstehende lebendige Kunstlandschaft Oberschwabens, die zunehmende Anerkennung von Erwin Henning und die daraus wachsenden persönlichen  Kontakte u.a. zu Ida Kerkovius, O.H.Hajek, Sepp Mahler und vor allem HAP Grieshaber.</td></tr>
                    <tr><td>Ab  1965</td><td>jährliche Arbeitsaufenthalte am Comersee.</td></tr>
                    <tr><td>1966</td><td>Erste Begegnung mit Herbert Kitzel und seinem Werk in dessen Einzelausstellung in der Städtischen Galerie „Altes Theater“ in Ravensburg.</td></tr>
                    <tr><td>1969-75</td><td>Studium an der Staatlichen Akademie der bildenden Künste Karlsruhe bei den Professoren Herbert Kitzel und Horst Egon Kalinowski.</td></tr>
                    <tr><td>Ab 1970</td><td>regelmäßige Beteiligung an Ausstellungen</td></tr>
                    <tr><td>1973</td><td>Meisterschüler von Herbert Kitzel, Heirat mit der Studienkollegin Eva Specht</td></tr>
                    <tr><td>1978-79</td><td>Villa Massimo Preis, verbunden mit einem einjährigen Aufenthalt als Stipendiat in der Villa Massimo in Rom. <br /> Intensiver Austausch und langjährige Freundschaft mit den Mitstipendiaten, den Komponisten Ulrich Leyendecker und Wolfgang von Schweinitz, sowie der Lyrikerin Sarah Kirsch.</td></tr>
                    <tr><td>1978</td><td>Tod der Freunde Herbert Kitzel und Winfried Leuprecht</td></tr>
                    <tr><td>1980–91</td><td> Atelier im Künstlerhaus Stresemannstraße in Karlsruhe</td></tr>
                    <tr><td>1980</td><td>Kulturpreis der Städte Ravensburg und Weingarten <br /> Reisen nach Venezuela, Florida und New York.</td></tr>
                    <tr><td>1983-84</td><td>Lehrstuhlvertretung der Klasse Kalinowski an der Kunstakademie Karlsruhe</td></tr>
                    <tr><td>1984</td><td>Tod der Mutter Irma Henning</td></tr>
                    <tr><td>1986</td><td>Erwerb eines Hauses in Karlsruhe-Durlach</td></tr>
                    <tr><td>1991</td><td>Eigenes Atelier im Haus</td></tr>
                    <tr><td>1993</td><td>Tod des Vaters Erwin Henning <br /> und des langjährigen Freundes, des Pianisten David Levine</td></tr>
                    <tr><td>1994</td><td>nach dem Tod der Eltern und deren jahrelangen Betreuung wieder intensives Arbeiten <br /> im eigenen Atelier</td></tr>
                </tbody>
            </table>
            <div className="biografie-dual-container">
                <div>
                    <h2>
                        Mitgliedschaften
                    </h2>
                    <ul>
                        <li>Mitglied des Deutschen Künstlerbundes (ab 1975)</li>
                        <li>des Künstlerbundes Baden-Württemberg (ab 1971)</li>
                        <li>der Secession München (1971 bis 1988)</li>
                        <li>der SOB Sezession Oberschwaben Bodensee (von 1972 bis 1985)</li>
                        <li>und dem BBK Karlsruhe (ab 1971)</li>
                    </ul>
                    <h2>
                        Arbeiten in öffentlichen Sammlungen
                    </h2>
                    <ul>
                        <li>Stuttgart, Ministerium für Wissenschaft und Kunst</li>
                        <li>Stuttgart, Grafische Sammlung der Staatsgalerie</li>
                        <li>Regierungspräsidien Karlsruhe, Tübingen, Freiburg</li>
                        <li>Sammlung Landkreis Ravensburg</li>
                        <li>Städtische Sammlungen Karlsruhe, Stuttgart, Waiblingen, Leutkirch, Laupheim</li>
                        <li>Sammlung Diethelm Lütze, Sindelfingen</li>
                        <li>Kreissparkassen Ravensburg, Girokasse Stuttgart, Volksbank Allgäu – Oberschwaben</li>
                    </ul>
                </div>
                <div>
                    <img className="biografie-portrait" src="/assets/images/WolfgangHenning.png" alt="Portrait von Wolfgang Henning" />
                    <p>Foto Hedi Baumgärtner</p>
                </div>
            </div>
        </div>
    );
};

export default Biografie;
