import React, { useEffect, useState } from "react";
import Slideshow from "./Slideshow";

type Props = {
    ordner: string | undefined;
    height: string;
    width: string;
    index?: number;
    fullscreenMode?: boolean;
}

interface Bild {
    name: string;
    jahr: string;
    technik: string;
    größe: string;
    ort: string;
    pfad: string;
}

const ImageOverview: React.FC<Props> = ({ ordner, index = 0, fullscreenMode = true }) => {

    const [bilder, setBilder] = useState([]);
    const [slideIndex, setIndex] = useState(index);
    const [fullscreen, setFull] = useState(false);

    function previewClickHandler(id: number) {
        setIndex(id);
        setFull(true);
    }

    useEffect(() => {
        let pfad = `/assets/images/${ordner}/bilder.json`;
        fetch(pfad)
            .then(themen => themen.json())
            .then((data) => {
                setBilder(data.map((bild: Bild, id: number) => {
                    return (
                        <button key={`preview-${bild.pfad}`}
                            className="image-overview-img"
                            style={{ backgroundImage: `url('/assets/images/${ordner}/${bild.pfad}')` }}
                            onClick={(e) => { previewClickHandler(id) }}
                        />
                    );
                }));
            });
    }, [ordner, fullscreenMode]);

    return (
        <div>
            {fullscreen ? <Slideshow fullscreenMode={false} index={slideIndex} ordner={ordner} setFull={ setFull } /> : null}
            <div className="image-overview">
                {bilder}
            </div>
        </div>
    );
};

export default ImageOverview;
