import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
}

const Header: React.FC<Props> = () => {

    return(
        <header>
            <div className="header-wrapper">
                <NavLink to="/"><h1>Wolfgang Henning</h1></NavLink>
                <div className="top-nav">
                    <NavLink to="/galerie">Galerie</NavLink>
                    <NavLink to="/ausstellungen">Ausstellungen</NavLink>
                    <NavLink to="/biografie">Biografie</NavLink>
                    <NavLink to="/literatur">Literatur</NavLink>
                    <NavLink to="/links">Links</NavLink>
                </div>
            </div>
        </header>
    );
};

export default Header;
