import React from "react";

type Props = {
}

const Literatur: React.FC<Props> = () => {

    return(
        <div className="content text-wrapper">
            <h1>
                Literatur
            </h1>
            <h2>
                Monografie
            </h2>
            <ul>
                <li>„Wolfgang Henning - Malerei-Zeichnung“ Förderkreis Galerie im Torhaus, Stadt Leutkirch, hg.1996, Verlag Rud. Roth KG</li>
                <li>„Wolfgang Henning - Engel“ 1986 bis 2021, Selbstverlag 2021</li>
                <li>„Wolfgang Henning – Portraits und Jagdgesellschaften“ R.M.Fischer Hg., Museum Langenargen 2023</li>
            </ul>
            <h2>
                Literaturangaben (alphabetisch)
            </h2>
            <ul>
                <li>Badischer Kunstverein Karlsruhe, hg.1983  „Villa Massimo, Studiengäste 1978-1982“</li>
                <li>Herbert Berner, hg. 1980 „Kunst um den Bodensee“ Kulturamt Singen/Htwl.</li>
                <li>BBK Karlsruhe, hg. 1999 „125 Jahre“</li>
                <li>Billing-Haus, Karlsruhe, hg.1991 „Eröffnung des Billing-Hauses“</li>
                <li>Ottmar Boneberger, „Stromland“, Lyrik, Prosa, Zeichnungen, edition ogb.1981</li>
                <li>Bundesinnenministerium hg. 1977 „Villa Massimo in Rom, Auswahl der Studiengäste“, Oberhausen</li>
                <li>Deutscher Künstlerbund e.V., hg. 2011 „Tradition und Zukunft“</li>
                <li>Andre Ficus, hg., „Sezession Oberschwaben Bodensee – Maler und Bildhauer in einer Landschaft 1947-77“ Robert Gessler-Verlag </li>1977
                <li>Andre Ficus, „Blick auf die SOB“, Förderkreis Galerie im Torhaus Leutkirch, hg. 1993, Verlag R.Gessler</li>
                <li>Ralf Michael Fischer, Hg.: „Wolfgang Henning - Portraits und Jagdgesellschaften“ – Museum Langenargen 2023</li>
                <li>Ralf Michael Fischer „Portraits und Jagdgesellschaften“ – S.15-48, Museum Langenargen 2023</li>
                <li>Ralf Michael Fischer „Interview mit Wolfgang Henning“ – S.49-89, Museum Langenargen 2023</li>
                <li>Katja Förster „Villa Massimo, Rom – Künstler der Galerie Knecht“, Karlsruhe 2008</li>
                <li>Heike Frommer, Hg.: “Der goldene Apfel – Jubiläumsausstellung 50 Jahre Bodenseekreis“, 2023</li>
                <li>Wilhelm Gall, „Handzeichnungen von Wolfgang Henning“, in: „Wolfgang Henning – Malerei Zeichnung“, 1996</li>
                <li>Axel Heil „Kreuzwege – Herbert Kitzel und sein Kreis“ in: Quellen, Mündungen, Landeskulturwochen, Villingen-Schwenningen 1998</li>
                <li>Mark R.Hesslinger „Erinnerung an das Schöne – Süddeutsche Künstler in der Villa Massimo in Rom“, S. 7-17, hrsg. Kunststiftung Hohenkarpfen e.V., 2023</li>
                <li>Sarah Kirsch „Sieben Häute“, Anabis-Verlag, Berlin 1979</li>
                <li>Konstanz, Kunstverein Wessenberghaus, hg. 1971 „Junge Kunst am Bodensee“</li>
                <li>Gode Krämer, „Landschaft“, 4.Nationale der Zeichnung, Augsburg 1988</li>
                <li>Jürgen Krüger, „Transzendenzerfahrungen“, Roncalli-Forum, hg.2019, arte factum</li>
                <li>Elmar L.Kuhn, Eva Moser, Ingrid Schättin „Gruppenbild vor Landschaft, die SOB 1947-1985“, hg.1997  Gesellschaft Oberschwaben, </li>Gessler-Verlag, Friedrichshafen
                <li>Kunstkreis Eppingen hg. 1980 „Karlsruher Künstler“</li>
                <li>KBBW Künstlerbund Baden-Württemberg e.V. hg.2005 „A bis Z“, 50 Jahre KBBW, modo Verlag, Freiburg</li>
                <li>KBBW e.V. hg.2012 „Gestern, heute, morgen“, der KBBW zum 60 jährigen Jubiläum des Landes B-W, modo Verlag</li>
                <li>KBBW e.V. hg.2015 „Was bleibt- Umgang mit Künstlernachlässe“, modo Verlag</li>
                <li>KBBW e.V. hg.2015 „Alle – 60 Jahre KBBW“, modo Verlag</li>
                <li>KBBW e.V. hg.2016 „Die Kunst zu handeln“, der KBBW und Galerien in Baden-Württemberg, modo Verlag</li>
                <li>KBBW e.V. hg.2018 „Idee, Entwurf,Konzept“, Edition Cantz, Esslingen</li>
                <li>Künstlerverzeichnis Baden-Württemberg, Bd.IV, Karlsruhe, Verlag Johannes Krause 1982</li>
                <li>Gisela Linder „Mahnmale für mehr Menschlichkeit“, 1987 in: „Wolfgang Henning – Malerei, Zeichnung“ 1996</li>
                <li>Ariane Lindemann, „Karlsruher Köpfe“, Info Verlag Karlsruhe, hg.2004</li>
                <li>Eva Moser „Kunst der Moderne in der Landschaft Bodensee-Oberschwaben“, Landratsamt Bodenseekreis, Friedrichshafen, hg.1984</li>
                <li>Martin Oswald, Rosemarie Langer, Stefanie Dathe „Kunst in Oberschwaben 1970 bis heute“, Gesellschaft Oberschwaben hg.2014, </li>Kunstverlag Josef Fink
                <li>Hans-Albert Peters, „Forum junge Kunst – Zeichnungen“, 20 Jahre Gesellschaft der Freunde junger Kunst, Baden-Baden hg.1975  C.</li>P.Müller-Verlag Karlsruhe
                <li>Annette Pfaff-Stöhr, „Kunst um den Bodensee“ Kulturamt Singen/Htwl. hg.1980</li>
                <li>Annette Pfaff-Stöhr, „Kunst am See“, Verlag R.Gessler, Friedrichshafen hg.1981</li>
                <li>Andreas Peiffer, hg. „Künstler aus Baden-Württemberg arbeiten in Rom in der Villa Massimo (1957-1982)“, Bonn, Heilbonn, 1982</li>
                <li>Gerd Presler, „Bilder Karlsruher Künstler-/innen im Dialog mit der Bibel“, Evangelisches Dekanat Karlsruhe, hg.1992</li>
                <li>Peter Renz „Zu Wolfgang Hennings Malerei“ in: „Wolfgang Henning – Malerei, Zeichnung“ 1996</li>
                <li>Rom, Galleria Rondanini „Incontri con artisti stranieri a Roma“, 1978</li>
                <li>Rud.Roth-Verlag hg.2017, „Leben in Leutkirch - Stadt-Land-Leute“</li>
                <li>Andreas Ruess „Positionen – 50 Jahre „Fähre“ in Saulgau“, Kulturamt der Stadt Bad Saulgau, hg. 1997</li>
                <li>Michael Schwarz, „Karlsruher Künstler“, Badischer Kunstverein, hg.1977</li>
                <li>Hans-Georg Sehrt „Herbert Kitzel und Freunde“, Hallescher Kunstverein, Galerie Knecht Karlsruhe, hg.2007</li>
                <li>Alfred Steiner, „Kunst der Gegenwart und das Religiöse“, Förderkreis Galerie im Torhaus, Leutkirch, hg.1992</li>
                <li>Kirsten Claudia Voigt, „Existenzielles und geordnete Ruhe“, 1987 in: „Wolfgang Henning – Malerei, Zeichnung“ 1996</li>
                <li>Kirsten Claudia Voigt „Der Prophet und die Jäger“ in: „Wolfgang Henning – Malerei, Zeichnung“ 1996</li>
                <li>Kirsten Claudia Voigt „Herbert Kitzel – die Zeit in Karlsruhe“ in:  Hans-Georg Sehrt „Herbert Kitzel und Freunde“, Hallescher </li>
                <li>Kirsten Claudia Voigt „Wunderliche Figuren entstehen“, S.10-14 in „Wolfgang Henning – Portraits und Jagdgesellschaften“, Museum Langenargen 2023</li>
                <li>Kunstverein, Galerie Knecht Karlsruhe, hg.2007</li>
                <li>Günther Wirth „Der Zeichner Wolfgang Henning“ 1977 in: „Wolfgang Henning – Malerei, Zeichnung“ 1996</li>
                <li>Günther Wirth „Kunst im deutschen Südwesten“, Hatje Verlag 1983</li>
                <li>Elisabeth Wolken, Rom, Galerie der Villa Massimo, „Studienjahr 1978/79“ Deutsche Akademie Villa Massimo 1978</li>
            </ul>
            <h2>
                Ausstellungskataloge
            </h2>
            <ol>
                <li>Künstlerverbände - Jahresausstellungen
                    <ul>
                        <li>Deutscher Künstlerbund e.V. ab 1975</li>
                        <li>Künstlerbund Baden-Württemberg, ab 1970</li>
                        <li>Münchner Secession, 1971-1988</li>
                        <li>SOB (Sezession Oberschwaben Bodensee) 1972-1985</li>
                    </ul>
                </li>
                <li>Jahresausstellungen
                    <ul>
                        <li>Darmstädter Sezession, 1973 – 1979</li>
                        <li>Darmstadt im Quadrat, Eduard Roether-Verlag 1981</li>
                        <li>Singener Kunstausstellungen, 1971 – 1975</li>
                        <li>Gönner,Paul, hg.- Hilzinger Kunstausstellung, 1980-1983</li>
                    </ul>
                </li>
                <li>Kunstmessen
                    <ul>
                        <li>art-karlsruhe, 2005, 2007, 2010, 2012</li>
                        <li>art-bodensee, 2007, 2008</li>
                    </ul>
                </li>
                <li>Sammlungen
                    <ul>
                        <li>Kunstförderung des Landes Baden-Württemberg, Erwerbungen 1976, MKW hg. Baden-Baden, Kunsthalle</li>
                        <li>Kunstförderung des Landes Baden-Württemberg, Erwerbungen 1977, MKW hg. Stuttgart, Württ.Kunstverein</li>
                        <li>Kunstförderung des Landes Baden-Württemberg, Erwerbungen 1978, MKW hg. Freiburg,Schwarzes Kloster</li>
                        <li>Kunstförderung des Landes Baden-Württemberg, Erwerbungen 1979, MKW hg. Tübingen,Kunsthalle</li>
                        <li>Kunstförderung des Landes Baden-Württemberg, Erwerbungen 1980, MKW hg.  Pforzheim, Kunstverein</li>
                        <li>Kunstförderung des Landes Baden-Württemberg, Erwerbungen 1981-82, MKW hg. Lörrach, Villa Aichele</li>
                        <li>Kunstförderung des Landes Baden-Württemberg, Erwerbungen 1983-85, MKW hg. Ulm, Museum</li>
                        <li>Kunstförderung des Landes Baden-Württemberg, Erwerbungen 1986-88, MKW hg. Esslingen, Villa Merkel</li>
                        <li>Kunstförderung des Landes Baden-Württemberg, Erwerbungen 1989-92, MKW hg. Singen, Kunstmuseum</li>
                        <li>Kunstförderung des Landes Baden-Württemberg, Erwerbungen 1993-96, MKW hg. Karlsruhe, Kunstverein</li>
                        <li>Karlsruhe, Badisches Landesmuseum, Irina Bode, Johanna Figiel  „Neue Künstlerkeramik a. d. Majolika“ 2007</li>
                        <li>Karlsruhe, Städt.Galerie, Erika Rödiger-Diruf, „Erwerbungen 1981-91“, hg. Stadt Karlsruhe 1991</li>
                        <li>Karlsruhe, Städt.Galerie, Brigitte Baumstark, „Bildwechsel 3 – Figur“, hg. Stadt Karlsruhe 1999</li>
                        <li>Leutkirch, Städt.Sammlung im Kornhaus, Alfred Steiner „Begegnung mit Malern“, hg. Stadt Leutkirch 1988</li>
                        <li>Ravensburg, Kreissparkasse, Babette Caesar „Kleinode – Kunst am Ort“, hg. Kreissparkasse Ravensburg 2001</li>
                        <li>Waiblingen, Städt.Sammlung, Günther Wirth, Helga Gebhardt, „Kunst im Rathaus“, hg. Stadt Waiblingen 1986</li>
                    </ul>
                </li>
            </ol>

            <h2>
                Zeitschriften
            </h2>
            <ul>
                <li>Ottmar Boneberger, „Körper und Landschaft“ in „Das Pult“, Zeitschrift für Literatur und Kritik Nr. 48, 1978</li>
                <li>Klaus Jürgen Fischer, „Gezeichnete Räume“ in „Das Kunstwerk“, Nr. 4/8, 1978</li>
                <li>Sven Knebel, hg. „Spektrum“, Internationale Vierteljahreszeitschrift für Dichtung und Originalgrafik, Zürich;</li>
                <li>Sven Knebel, hg. „Spektrum“, Sondernummer Nr. 92, 1981;</li>
                <li>Sven Knebel, hg. „Spektrum“, Nr. 97 „Links/Rechts“, 1982;</li>
                <li>Sven Knebel, hg. „Spektrum“, Nr. 99 „Ränder“, 1983;</li>
                <li>Sven Knebel, hg. „Spektrum“, Nr. 100, Jubiläumsnummer 25 Jahre, 1983;</li>
                <li>Sven Knebel, hg. „Spektrum“, Nr. 101, „Hinterland“,1983/1984;</li>
                <li>Sven Knebel, hg. „Spektrum“, Nr. 109, „Stille/Ruhe“, 1985;</li>
                <li>Sven Knebel, hg. „Spektrum“, Nr. 111, „Gaukler“, 1986;</li>
                <li>Sven Knebel, hg. „Spektrum“, Nr.112, „Erinnerung“ 1986;</li>
                <li>Franz Littmann, „Die beste Maske, die wir tragen, ist unser eigenes Gesicht“ (Ausstellungsbesprechung) in „Klappe auf“, Karlsruher Kulturmagazin, Okt. 1994</li>
                <li>Rolfrafael Schröer, „Römisches Lamento“ in Jahrbuch der Villa Massimo, Rom, 1978/79</li>
                <li>Martin Walser, „Literatur contra Leid“ in „Radius-Almanach“ hg. Wolfgang Erk, Radius Verlag Stuttgart 1980/81</li>
                <li>Günther Wirth, „Wolfgang Henning – Zeichnungen“ in Galerienachrichten Galerie Hartwig, Edition Hartwig Berlin 1980</li>
            </ul>
        </div>
    );
};

export default Literatur;
