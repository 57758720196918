import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import ImageOverview from "../Components/ImageOverview";

type Props = {
    themen: ThemenObj | undefined;
}

type ThemaObj = {
    titel: string;
    ordner: string;
    bild: string;
    zitat: {
        text: string;
        quelle: string;
    }
}

export type ThemenObj = {
    [index: string]: ThemaObj[];
}

const Thema: React.FC<Props> = ({ themen }) => {

    const [thema, setThema] = useState<ThemaObj>();
    const [gliederung, setGliederung] = useState<any>();

    const { technik, ordner } = useParams<string>();

    useEffect(() => {
        if ((technik !== undefined) && (themen !== undefined)) {
            themen[technik].forEach(e => {
                if (e.ordner === technik?.concat('/', ordner!)) {
                    setThema(e);
                }
            });
        }
    }, [technik, ordner, themen]);

    useEffect(() => {
        if ((technik !== undefined) && (themen !== undefined)) {
            setGliederung(themen[technik].map(e => {
                console.log("Thema: " + thema?.titel);
                console.log("E:" + e.titel);

                return (
                    <NavLink to={encodeURI(`/galerie/${e.ordner}`)} >{e.titel}</NavLink>
                );
            }));
        }
    }, [technik, thema, themen]);

    return(
        <div className="content slideshow-container">
            <div className="thema-left-container">
                <div className="thema-title">{thema?.titel}</div>
                <div>
                    {
                        thema?.zitat.text === "" || thema === undefined ? null:
                        <div className="zitat">
                            <q dangerouslySetInnerHTML={{__html: thema?.zitat.text}}></q>
                            <p dangerouslySetInnerHTML={{__html: thema?.zitat.quelle}}></p>
                        </div>
                    }
                </div>
                <div className="gliederung">
                    {gliederung}
                </div>
            </div>
            <ImageOverview height="700px" width="500px" ordner={technik?.concat('/', ordner!)}/>
        </div>
    );
};

export default Thema;
