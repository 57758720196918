import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
}

const Footer: React.FC<Props> = () => {

    return(
        <footer>
            <address>
                <p>Wolfgang Henning</p>
                <a href="mailto:info@wolfgang-henning.de">info@wolfgang-henning.de</a>
            </address>
            <div>
                <p>Copyright © 2022 Wolfgang Henning und VG Bild-Kunst 2022</p>
                <NavLink to="/impressum">Impressum | Datenschutz</NavLink>
            </div>
            <address>
                <p>Programmierung: David Specht </p>
                <a href="mailto:DavidSpecht@protonmail.com">DavidSpecht@protonmail.com</a>
            </address>
        </footer>
    );
};

export default Footer;
