import React, { useEffect, useState } from "react";

type Props = {
    ordner: string | undefined;
    setFull: React.Dispatch<React.SetStateAction<boolean>>;
    index?: number;
    fullscreenMode?: boolean;
}

interface Bild {
    name: string;
    jahr: string;
    technik: string;
    größe: string;
    ort: string;
    pfad: string;
}

const Slideshow: React.FC<Props> = ({ ordner, index = 0, setFull}) => {

    const [bilder, setBilder] = useState([]);
    const [bildInfo, setInfo] = useState<Bild[]>();
    const [slideIndex, setIndex] = useState(index);

    useEffect(() => {
        let pfad = `/assets/images/${ordner}/bilder.json`;
        fetch(pfad)
            .then(themen => themen.json())
            .then((data) => {
                setInfo(data);
                setBilder(data.map((bild: Bild) => {
                    return (
                        <img key={bild.pfad}
                            className="slideshow-image"
                            src={`/assets/images/${ordner}/${bild.pfad}`}
                            alt={`Bild mit dem Titel "${bild.name}"`}
                        />
                    );
                }));
            });
    }, [ordner]);

    const InfoBox = () => {
            return (
                <table className="slideshow-image-info-box">
                    <tbody>
                        <tr>
                            <td>
                                Titel:
                            </td>
                            <td>
                                {bildInfo?.at(slideIndex)?.name}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Jahr:
                            </td>
                            <td>
                                {bildInfo?.at(slideIndex)?.jahr}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Technik:
                            </td>
                            <td>
                                {bildInfo?.at(slideIndex)?.technik}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Größe:
                            </td>
                            <td>
                                {bildInfo?.at(slideIndex)?.größe}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Ort:
                            </td>
                            <td>
                                {bildInfo?.at(slideIndex)?.ort}
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
    }

    function indexAdd(value: number) {
        let index = slideIndex + value;
        if (index < 0) index += bilder.length;
        index = index % bilder.length;
        document.getElementById(`slideshow-preview-btn-${index}`)?.scrollIntoView({behavior: "smooth"});
        setIndex(index);
    }

    console.log(bilder);


    return (
        <div className="slideshow-fullscreen-container">
            <button className="slideshow-fullscreen-close-btn" onClick={(e)=>setFull(false)}>&times;</button>
            <div className="slideshow">
                <div className="slideshow-image-container">
                    {bilder[slideIndex]}
                </div>
                <div className="slideshow-button-container slideshow-left">
                    <button className="slideshow-button" onClick={e => { indexAdd(-1) }}>❮</button>
                    <div className="slideshow-image-text">
                        <InfoBox/>
                    </div>
                </div>
                <div className="slideshow-button-container slideshow-right">
                    <button className="slideshow-button" onClick={e => { indexAdd(1) }}>❯</button>
                </div>
            </div>
        </div>
    );
};

export default Slideshow;
