import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import Thema, { ThemenObj } from "./Thema";

type Props = {
}

interface Kachel{
    titel: string;
    ordner: string;
    bild: string;
}

const Galerie: React.FC<Props> = () => {
    const [zeichnungen, setZeichnungen] = useState([]);
    const [malerei, setMalerei] = useState([]);
    const [grafik, setGrafik] = useState([]);
    const [themen, setThemen] = useState<ThemenObj>();

    useEffect(() => {
        fetch("/assets/images/Themen.json")
            .then(themen => themen.json())
            .then((data) => {
                setThemen(data);
                setGrafik(data['Grafik - Variatio Delectat'].map((kachel: Kachel) => {
                    return (
                        <NavLink to={kachel.ordner} className="image-grid-item" style={{ backgroundImage: `url('${kachel.bild}')` }} key={kachel.titel}>
                            <div className="text-overlay">{kachel.titel}</div>
                        </NavLink>
                    );
                }));
                setMalerei(data['Malerei'].map((kachel: Kachel) => {
                    return (
                        <NavLink to={kachel.ordner} className="image-grid-item" style={{ backgroundImage: `url('${kachel.bild}')` }} key={kachel.titel}>
                            <div className="text-overlay">{kachel.titel}</div>
                        </NavLink>
                    );
                }));
                setZeichnungen(data['Zeichnungen'].map((kachel: Kachel) => {
                    return (
                        <NavLink to={kachel.ordner} className="image-grid-item" style={{ backgroundImage: `url('${kachel.bild}')` }} key={kachel.titel}>
                            <div className="text-overlay">{kachel.titel}</div>
                        </NavLink>
                    );
                }));
            });
    }, []);

    const ImageGrid: any = () => {
        return (
            <div className="content center">
                <div className="image-grid-container">
                    <h1>Zeichnungen</h1>
                    <div className="image-grid">
                        {zeichnungen}
                    </div>
                    <h1>Malerei</h1>
                    <div className="image-grid">
                      {malerei}
                    </div>
                    <h1>Grafik - Variatio Delectat</h1>
                    <div id="grafik" className="image-grid">
                        <div className="image-grid-text">Henning erhielt vor einigen Jahren ein ausgebrauchtes Kopiergerät geschenkt und entdeckte nun den Reiz und den Reichtum technischer Reproduzierbarkeit. Er zeichnete, kopierte, zeichnete wieder in die Kopie hinein. So entstanden reihenweise Blätter, die sich ähneln und von denen jedes doch - durch die spezifische Zutat - ein Original ist. Indem Henning das technische Gerät mit dem alten manuellen Medium des Zeichnens kombiniert, bringt er die spielerische Seite seiner Kunst zum Vorschein.
                        <p>Zitat aus: Michael Hübl - BNN 1.12.2006 Kultur</p>
                        </div>
                        {grafik}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<ImageGrid />}></Route>
            <Route path="/:technik/:ordner" element={<Thema themen={themen} />}></Route>
        </Routes>
    );
};

export default Galerie;
