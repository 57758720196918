import React from "react";

type Props = {
}

const Links: React.FC<Props> = () => {

    return (
        <div className="content">
            <h1>Links</h1>
            <h2>Ausstellung Langenargen</h2>
            <a href="https://www.diebildschirmzeitung.de/diebildschirmzeitung/leutkirch/leutkirch-le/15916-zwei-leutkircher-maler-im-dialog">https://www.diebildschirmzeitung.de/diebildschirmzeitung/leutkirch/leutkirch-le/15916-zwei-leutkircher-maler-im-dialog</a>
            <br />
            <a href="https://kuneonline.net/2023/08/27/ein-museum-drei-ausstellungen-ein-besuch-in-langenargen/">https://kuneonline.net/2023/08/27/ein-museum-drei-ausstellungen-ein-besuch-in-langenargen/</a>
            <h2>Deutscher Künstlerbund</h2>
            <a href="https://www.kuenstlerbund.de/deutsch/mitglieder/ordentliche-mitglieder/henning-wolfgang.html">https://www.kuenstlerbund.de/deutsch/mitglieder/ordentliche-mitglieder/henning-wolfgang.html</a>
            <h2>Künstlerbund Baden-Württemberg</h2>
            <a href="https://www.kuenstlerbund-bawue.de/profil/wolfgang.alfred.henning/">https://www.kuenstlerbund-bawue.de/profil/wolfgang.alfred.henning/</a>
            <h2>Künstlerhaus Karlsruhe BBK</h2>
            <a href="https://www.bbk-karlsruhe.de/bbk_seiten/kuenstler-wp.php?kunr=32&kuname=Henning&kuvorname=Wolfgang">https://www.bbk-karlsruhe.de/bbk_seiten/kuenstler-wp.php?kunr=32&kuname=Henning&kuvorname=Wolfgang</a>
            <h2>Galerie Knecht und Burster</h2>
            <a href="https://galerie-knecht-und-burster.de/galerie">https://galerie-knecht-und-burster.de/galerie</a>
            <h2>Wikipedia</h2>
            <a href="https://de.wikipedia.org/wiki/Wolfgang_Henning_(Maler)">https://de.wikipedia.org/wiki/Wolfgang_Henning_(Maler)</a>
        </div>
    );
};

export default Links;
