import React from "react";

type Props = {
}

const Ausstellungen: React.FC<Props> = () => {

    return (
        <div className="content text-wrapper">
            <h1>Ausstellungen</h1>
            <h2>
                Einzelausstellungen
            </h2>
            <table>
                <tbody>
                    <tr><td>1971</td><td>Tübingen,  Galerie im Zimmertheater (zur Premiere von Samuel Beckett „Glückliche Tage“)</td></tr>
                    <tr><td>1974</td><td>Ravensburg,  Kreissparkasse (Vater und Sohn)</td></tr>
                    <tr><td>1976</td><td>Stuttgart,  Girokasse (mit Elke Wree)</td></tr>
                    <tr><td>1977</td><td>Biberach/Riß,  Braith-Mali Museum</td></tr>
                    <tr><td>1979</td><td>Karlsruhe,  SüdWestGalerie (mit Lutz Wolf und Verena Vernunft)</td></tr>
                    <tr><td>1979</td><td>Waiblingen,  Galerie im Rathaus (mit Manfred Woitischek)</td></tr>
                    <tr><td>1980</td><td>Schramberg,  Podium Kunst</td></tr>
                    <tr><td>1981</td><td>Tuttlingen,   Städt.Galerie (Vater und Sohn)</td></tr>
                    <tr><td>1981</td><td>Berlin,  Galerie Hartwig ( unter Mitwirkung von David Levine und Stephan Wigger)</td></tr>
                    <tr><td>1984</td><td>Kirchheim/Teck,  Kulturrring</td></tr>
                    <tr><td>1984</td><td>Tettnang,  Galerie im Torschloß (mit Vera Issleiber)</td></tr>
                    <tr><td>1984</td><td>Leutkirch/Allgäu,  Galerie im Torhaus</td></tr>
                    <tr><td>1987</td><td>Weingarten,  Kornhaus Galerie</td></tr>
                    <tr><td>1987</td><td>Karlsruhe,   Galerie HILBUR (mit Vera Issleiber)</td></tr>
                    <tr><td>1989</td><td>Laupheim,  Städt. Galerie „Schranne“  (Vater und Sohn)</td></tr>
                    <tr><td>1990</td><td>Pforzheim,  Kunstverein im Reuchlinhaus</td></tr>
                    <tr><td>1990</td><td>Karlsruhe,   Staatliche Kunsthalle (mit Vera Issleiber)</td></tr>
                    <tr><td>1994</td><td>Karlsruhe,   Galerie art-contact</td></tr>
                    <tr><td>1996</td><td>Leutkirch/Allgäu,   Städt.Galerie im Kornhaus, Galerie im Torhaus</td></tr>
                    <tr><td>1997</td><td>Laupheim,  Städt.Galerie „Schranne“</td></tr>
                    <tr><td>1998</td><td>Karlsruhe,  Galerie Knecht</td></tr>
                    <tr><td>2003</td><td>Karlsruhe,  Galerie Knecht, Kabinettausstellung in der Reihe „Begegnung mit H.Kitzel“</td></tr>
                    <tr><td>2006</td><td>Oberschönenfeld,  Schwäbische Galerie im Volkskundemuseum (Vater und Sohn)</td></tr>
                    <tr><td>2006</td><td>Karlsruhe, Galerie Knecht&Burster</td></tr>
                    <tr><td>2008</td><td>Isny/Allgäu,  Städt.Galerie im Turm Espantor</td></tr>
                    <tr><td>2010</td><td>Baienfurt,  Galerie im Rathaus</td></tr>
                    <tr><td>2015</td><td>Karlsruhe,  Galerie Knecht&Burster</td></tr>
                    <tr><td>2016</td><td>Leutkirch/Allgäu,   Galerie im Torhaus</td></tr>
                    <tr><td>2023</td><td>Langenargen,  Purrmann-Museum  „WOLFGANG HENNING – Portraits und Jagdgesellschaften“ (im Rahmen der Trüffelsuche des Künstlerbundes Baden-Württemberg)</td></tr>
                </tbody>
            </table>
            <h2>
                Ausstellungsbeteiligungen
            </h2>
            Als Mitglied Beteiligungen bei:
            <ul>
                <li>dem Künstlerbund Baden-Württemberg – KBBW (ab 1970),</li>
                <li>der Münchner Secession im Haus der Kunst (1971-1988),</li>
                <li>der Sezession Oberschwaben Bodensee(SOB) (1972-1985),</li>
                <li>dem Deutschen Künstlerbund (ab 1975)</li>
            </ul>
            <table>
                <tbody>
                    <tr><td>1970</td><td>Hannover – Galerie Koch, Erotische Kunst</td></tr>
                    <tr><td></td><td>Frankfurt/Main – Galerie Ostheimer, Erotische Kunst</td></tr>
                    <tr><td>1971</td><td>Konstanz -  Wessenberghaus, Junge Kunst am Bodensee</td></tr>
                    <tr><td></td><td>New Hope - Galerie für internationale Grafik</td></tr>
                    <tr><td></td><td>Karlsruhe – Badischer Kunstverein, Jahresausstellung</td></tr>
                    <tr><td></td><td>Singen/Htwl. – Singener Kunstausstellung</td></tr>
                    <tr><td>1972</td><td>Friedrichshafen – Bodenseemuseum, Bodenseeclub</td></tr>
                    <tr><td></td><td>Ravensburg – Kreissparkasse, Maler sehen Oberschwaben</td></tr>
                    <tr><td></td><td>Singen/Htwl. – Singener Kunstausstellung</td></tr>
                    <tr><td></td><td>Karlsruhe – Badischer Kunstverein, Jahresausstellung</td></tr>
                    <tr><td>1973</td><td>Recklinghausen – Kunsthalle, Poesie mit Material</td></tr>
                    <tr><td></td><td>Singen/Htwl. - Singener Kunstausstellung</td></tr>
                    <tr><td></td><td>Karlsruhe – Badischer Kunstverein, Jahresausstellung</td></tr>
                    <tr><td></td><td>Darmstadt – Mathildenhöhe, Darmstädter Sezession</td></tr>
                    <tr><td>1974</td><td>Singen/Htwl. - Singener Kunstausstellung</td></tr>
                    <tr><td></td><td>Karlsruhe – Badischer Kunstverein, Jahresausstellung</td></tr>
                    <tr><td></td><td>Darmstadt – Mathildenhöhe, Darmstädter Sezession</td></tr>
                    <tr><td>1975</td><td>Baden-Baden – Staatliche Kunsthalle, Forum junger Kunst</td></tr>
                    <tr><td></td><td> Recklinghausen – Kunsthalle, Forum junger Kunst</td></tr>
                    <tr><td></td><td>Singen/Htwl. - Singener Kunstausstellung</td></tr>
                    <tr><td></td><td>Karlsruhe – Badischer Kunstverein, Jahresausstellung</td></tr>
                    <tr><td></td><td>Darmstadt – Mathildenhöhe, Darmstädter Sezession</td></tr>
                    <tr><td>1976</td><td>Ravensburg – Kreissparkasse, Künstler des Landkreises</td></tr>
                    <tr><td></td><td>Karlsruhe – Badischer Kunstverein, Jahresausstellung</td></tr>
                    <tr><td></td><td>Darmstadt – Mathildenhöhe, Darmstädter Sezession</td></tr>
                    <tr><td>1977</td><td>Oberhausen – Städtische Galerie im Schloss, Bewerber für Villa Massimo-Preis Rom</td></tr>
                    <tr><td></td><td>Baden-Baden – Staatliche Kunsthalle, Kunstförderung des Landes Baden-Württemberg</td></tr>
                    <tr><td></td><td>Karlsruhe – Badischer Kunstverein, Jahresausstellung</td></tr>
                    <tr><td></td><td>Darmstadt – Mathildenhöhe, Darmstädter Sezession</td></tr>
                    <tr><td></td><td>Karlsruhe – Badischer Kunstverein, Karlsruher Künstler</td></tr>
                    <tr><td>1978</td><td>Stuttgart – Kunstverein, Kunstförderung des Landes Baden-Württemberg</td></tr>
                    <tr><td></td><td>Rom – Galeria Rondanini, artisti stranieri a roma</td></tr>
                    <tr><td></td><td>Darmstadt – Mathildenhöhe, Darmstädter Sezession</td></tr>
                    <tr><td>1979</td><td>Rom – Galerie der Villa Massimo, Stipendiatenausstellung</td></tr>
                    <tr><td></td><td>Freiburg – Schwarzes Kloster, Kunstförderung des Landes Baden-Württemberg</td></tr>
                    <tr><td></td><td>Darmstadt – Mathildenhöhe, Darmstädter Sezession</td></tr>
                    <tr><td>1980</td><td>Eppingen – Kunstkreis, Karlsruher Künstler</td></tr>
                    <tr><td></td><td>Saulgau – Städtische Galerie „Die Fähre“, Kunst um den Bodensee</td></tr>
                    <tr><td></td><td>Bregenz – Künstlerhaus Thurn und Taxis, Kunst um den Bodensee</td></tr>
                    <tr><td></td><td>Singen/Htwl. – Rathaus, Kunst um den Bodensee</td></tr>
                    <tr><td></td><td>Tübingen- Kunsthalle, Kunstförderung des Landes Baden-Württemberg</td></tr>
                    <tr><td></td><td>Baden-Baden – Kunsthalle, Junge Kunst 1980</td></tr>
                    <tr><td></td><td>Hilzingen – Hilzinger Kunstausstellung</td></tr>
                    <tr><td>1981</td><td>Friedrichshafen – Bodenseemuseum, Junge Kunst am See</td></tr>
                    <tr><td></td><td>Ravensburg – Landratsamt, Ankäufe des Landkreises</td></tr>
                    <tr><td></td><td>Pforzheim – Kunstverein, Kunstförderung des Landes Baden-Württemberg</td></tr>
                    <tr><td></td><td>Hilzingen – Hilzinger Kunstausstellung</td></tr>
                    <tr><td>1982</td><td>Bonn – Haus Baden-Württemberg, Künstler aus Baden-Württemberg in Rom</td></tr>
                    <tr><td></td><td>Hilzingen – Hilzinger Kunstausstellung</td></tr>
                    <tr><td>1983</td><td>Lörrach – Villa Aichele, Kunstförderung des Landes Baden-Württemberg</td></tr>
                    <tr><td></td><td>Sindelfingen – Galerie der Stadt, Sammlung Lütze</td></tr>
                    <tr><td></td><td>Heilbronn – Städtisches Museum, Künstler aus Baden-Württemberg in Rom</td></tr>
                    <tr><td></td><td>Karlsruhe – Badischer Kunstverein, Studiengäste der Villa Massimo</td></tr>
                    <tr><td></td><td>Kassel – Orangerie Karlsaue, Studiengäste der Villa Massimo</td></tr>
                    <tr><td></td><td>Hilzingen – Hilzinger Kunstausstellung</td></tr>
                    <tr><td>1984</td><td>Karlsruhe – Prinz- Max- Palais – Städtische Sammlung, Sammlung Lütze</td></tr>
                    <tr><td>1985</td><td>Saulgau – Städtische Galerie „Fähre“, Fünf Maler in Oberschwaben</td></tr>
                    <tr><td>1986</td><td>Weingarten – Kornhausgalerie, Druckgrafik aus Südwestdeutschland</td></tr>
                    <tr><td></td><td>Waiblingen – Städtische Sammlung, Kunst im Rathaus</td></tr>
                    <tr><td></td><td>Karlsruhe – Künstlerhaus, Künstlerhäuser stellen sich vor ( Atelier Stresemannstraße)</td></tr>
                    <tr><td>1987</td><td>Augsburg – Sparkassengalerie, Galerie Minotaurus, 4. Nationale der Zeichnung</td></tr>
                    <tr><td>1988</td><td>Villingen-Schwenningen, Landeskunstwochen, Kreuzwege – Kitzel und sein Kreis</td></tr>
                    <tr><td>1989</td><td>Karlsruhe – Galerie HILBUR, 15 Jahre Galerie – Jubiläumsausstellung</td></tr>
                    <tr><td>1991</td><td>Karlsruhe – Billing-Haus, Ausstellung zur Eröffnung des Hauses</td></tr>
                    <tr><td>1992</td><td>Karlsruhe – Evangelisches Dekanat, Künstlerinnen u. Künstler im Dialog mit der Bibel</td></tr>
                    <tr><td></td><td>Leutkirch – Galerie im Torhaus, Die Kunst der Gegenwart und das Religiöse</td></tr>
                    <tr><td>1993</td><td>Leutkirch – Städtische Galerie im Kornhaus und Galerie im Torhaus, Blick auf die SOB</td></tr>
                    <tr><td>1994</td><td>Karlsruhe – Galerie art-contact, Kitzel und seine Schüler</td></tr>
                    <tr><td></td><td>Singen/Htwl. - Kunstmuseum, Kunstförderung des Landes Baden-Württemberg </td></tr>
                    <tr><td>1996</td><td>Karlsruhe – Orgelfabrik, zur 800 Jahrfeier von Durlach – Durlacher Künstler</td></tr>
                    <tr><td>1997</td><td>Karlsruhe – Galerie Knecht, Künstler der Galerie</td></tr>
                    <tr><td></td><td>Saulgau – Städtische Galerie „Die Fähre“, Positionen, 50 Jahre „Fähre“</td></tr>
                    <tr><td>1998</td><td>Karlsruhe – Badischer Kunstverein, Kunstförderung des Landes Baden-Württemberg</td></tr>
                    <tr><td></td><td>Ochsenhausen – Fruchthalle, Kunstförderung des Landes Baden-Württemberg</td></tr>
                    <tr><td></td><td>Schwäbisch Gmünd – Prediger, Kunstförderung des Landes Baden-Württemberg</td></tr>
                    <tr><td></td><td>Karlsruhe – Orgelfabrik, Künstler in Durlach</td></tr>
                    <tr><td>1999</td><td>Karlsruhe – Künstlerhaus, 125 Jahre BBK</td></tr>
                    <tr><td></td><td>Karlsruhe – Städtische Galerie, Bildwechsel</td></tr>
                    <tr><td>2000</td><td>Ravensburg – Kreissparkasse, Kunst am Ort – Kleinode</td></tr>
                    <tr><td></td><td>Mochental – Galerie Schrade, Sommer 2000 – Kunst aus Oberschwaben</td></tr>
                    <tr><td></td><td>Karlsruhe – Galerie Knecht, Hommage Mareile Kitzel</td></tr>
                    <tr><td>2001</td><td>Ettlingen – Wilhelmshöhe, Benefiz Wolfgang Hartmann-Preis</td></tr>
                    <tr><td>2002</td><td>Karlsruhe – Künstlerhaus, Mythos Europa</td></tr>
                    <tr><td>2003</td><td>Karlsruhe – Majolika, Kitzel und Schüler</td></tr>
                    <tr><td>2004</td><td>Karlsruhe – art karlsruhe, Galerie Knecht</td></tr>
                    <tr><td></td><td>Karlsruhe – Künstlerhaus, Das Haus</td></tr>
                    <tr><td>2005</td><td>Karlsruhe – art karlsruhe, Galerie Knecht</td></tr>
                    <tr><td></td><td>Karlsruhe – Billing- Haus, 100 Jahre Billing- Haus</td></tr>
                    <tr><td>2006</td><td>Karlsruhe – Galerie Knecht – Jahresausstellung</td></tr>
                    <tr><td>2007</td><td>Karlsruhe – Majolika, Neue Künstlerkeramik</td></tr>
                    <tr><td></td><td>Staufen – Keramikmuseum, Neue Künstlerkeramik aus der Majolika</td></tr>
                    <tr><td>2007</td><td>Dornbirn – art bodensee, Galerie Knecht</td></tr>
                    <tr><td></td><td>Karlsruhe – Orgelfabrik, Kitzel und Freunde</td></tr>
                    <tr><td></td><td>Halle – Hallescher Kunstverein am Domplatz, Kitzel und Freunde</td></tr>
                    <tr><td>2008</td><td>Dornbirn – art bodensee, Galerie Knecht</td></tr>
                    <tr><td></td><td>Neuenbürg – Schloss, Neue Künstlerkeramik aus der Majolika</td></tr>
                    <tr><td></td><td>Karlsruhe – Galerie Knecht&Burster, Künstler der Galerie in Rom</td></tr>
                    <tr><td>2009</td><td>Karlsruhe – Majolika, Kitzel und Schüler</td></tr>
                    <tr><td>2010</td><td>Biberach – Braith-Mali-Museum, Hochzeit im Oberland</td></tr>
                    <tr><td></td><td>Karlsruhe – art karlsruhe, Galerie Knecht&Burster</td></tr>
                    <tr><td></td><td>Karlsruhe – St. Michael, Kunst und Religion</td></tr>
                    <tr><td>2011</td><td>Karlsruhe – Städtische Galerie, Geschenkt</td></tr>
                    <tr><td></td><td>Karlsruhe – Galerie Knecht&Burster, Vorschau-Rückschau</td></tr>
                    <tr><td>2012</td><td>Karlsruhe – Billing-Haus, Freunde</td></tr>
                    <tr><td></td><td>Karlsruhe – Orgelfabrik, Bulusma – Begegnung</td></tr>
                    <tr><td></td><td>Karlsruhe – art karlsruhe, Galerie Knecht&Burster</td></tr>
                    <tr><td></td><td>Karlsruhe – Galerie Knecht&Burster, Vorschau-Rückschau</td></tr>
                    <tr><td></td><td>Stuttgart – Kunstgebäude am Schlossplatz, KBBW – Gestern-Heute-Morgen</td></tr>
                    <tr><td>2013</td><td>Wangen/Allgäu – Städtische Galerie in der Badstub, Baden-gehen V</td></tr>
                    <tr><td>2014</td><td>Karlsruhe – Galerie Knecht&Burster, Vorschau-Rückschau</td></tr>
                    <tr><td>2015</td><td>Karlsruhe – Städtische Galerie, KBBW  - ALLE</td></tr>
                    <tr><td>2016</td><td>Karlsruhe – Galerie Knecht&Burster, Die Kunst zu handeln, KBBW und Galerien</td></tr>
                    <tr><td>2017</td><td>Baienfurt – Rathaus, Ein Blick zurück nach vorn, Baienfurter Bilderbogen, Sammlung LK RV</td></tr>
                    <tr><td></td><td>Biberach/Riß – Braith-Mali-Museum, Kinder</td></tr>
                    <tr><td>2018</td><td>Wangen/Allgäu – Städtische Galerie in der Badstub,  Kinderzeit-schöne Zeit</td></tr>
                    <tr><td></td><td>Karlsruhe – Galerie Knecht&Burster, Rolf Zimmermann und Wegbegleiter</td></tr>
                    <tr><td>2019</td><td>Karlsruhe – St. Michael, Transzendenzerfahrungen in der Kunst</td></tr>
                    <tr><td>2020</td><td>Leutkirch – Kornhaus, Reset – Ankäufe der Stadt</td></tr>
                    <tr><td></td><td>Berlin – Vertretung des Landes Baden-Württemberg, KBBW – Plugin</td></tr>
                    <tr><td>2021</td><td>Halle/Saale – HAL – art 21, Galerie Knecht&Burster</td></tr>
                    <tr><td>2022</td><td>Karlsruhe,  Galerie Knecht&Burster - POSITIONEN - Hans Baschang/Peter Burger/Wolfgang Henning/Bodo Kraft/Dieter Krieg</td></tr>
                    <tr><td>2023</td><td>Ravensburg – Sparkassengalerie - Jubiläumsausstellung – Begegnung: „Contemporary“</td></tr>
                    <tr><td></td><td>Meersburg – Rotes Haus – „Der goldene Apfel“ – Jubiläumsausstellung 50 Jahre Bodenseekreis</td></tr>
                    <tr><td></td><td>Karlsruhe – Galerie Knecht- „Künstler der Galerie“</td></tr>
                    <tr><td></td><td>Hausen ob Verena – Kunststiftung Hohenkarpfen - „Erinnerung an das Schöne - Süddeutsche Künstler in der Villa Massimo in Rom“</td></tr>
                </tbody>
            </table>
        </div>
    );
};

export default Ausstellungen;
